<template>
  <div id="notify_bar">
    <div class="notify_bar_container">
      <TransitionGroup name="base"><div
        v-for="(msg, i) in msgs"
        :key="i"
        class="notify_bar_container__item msg_container"
        :class="`msg_type_${msg.type.toLowerCase()}`"
      >
        <div class="msg_container__item icon">
          <div
            v-if="['WAITING', 'INPROCESS'].includes(msg.type)"
            class="msg_container__item icon_wait"
          >
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4999 2.16732C8.28978 2.16732 6.17017 3.04529 4.60736 4.6081C3.04456 6.1709 2.16659 8.29051 2.16659 10.5007C2.16659 12.7108 3.04456 14.8304 4.60736 16.3932C6.17017 17.956 8.28978 18.834 10.4999 18.834C12.7101 18.834 14.8297 17.956 16.3925 16.3932C17.9553 14.8304 18.8333 12.7108 18.8333 10.5007C18.8333 8.29051 17.9553 6.1709 16.3925 4.6081C14.8297 3.04529 12.7101 2.16732 10.4999 2.16732ZM0.083252 10.5007C0.083252 4.74753 4.74679 0.0839844 10.4999 0.0839844C16.253 0.0839844 20.9166 4.74753 20.9166 10.5007C20.9166 16.2538 16.253 20.9173 10.4999 20.9173C4.74679 20.9173 0.083252 16.2538 0.083252 10.5007Z"
                fill="white"
              />
              <path
                d="M10.5 8.41667C10.7763 8.41667 11.0412 8.52641 11.2366 8.72176C11.4319 8.91711 11.5417 9.18207 11.5417 9.45833V15.7083C11.5417 15.9846 11.4319 16.2496 11.2366 16.4449C11.0412 16.6403 10.7763 16.75 10.5 16.75C10.2237 16.75 9.95878 16.6403 9.76343 16.4449C9.56808 16.2496 9.45833 15.9846 9.45833 15.7083V9.45833C9.45833 9.18207 9.56808 8.91711 9.76343 8.72176C9.95878 8.52641 10.2237 8.41667 10.5 8.41667ZM12.0625 5.8125C12.0625 6.2269 11.8979 6.62433 11.6049 6.91735C11.3118 7.21038 10.9144 7.375 10.5 7.375C10.0856 7.375 9.68817 7.21038 9.39515 6.91735C9.10212 6.62433 8.9375 6.2269 8.9375 5.8125C8.9375 5.3981 9.10212 5.00067 9.39515 4.70765C9.68817 4.41462 10.0856 4.25 10.5 4.25C10.9144 4.25 11.3118 4.41462 11.6049 4.70765C11.8979 5.00067 12.0625 5.3981 12.0625 5.8125Z"
                fill="white"
              />
            </svg>
          </div>
          <div v-if="'OK' === msg.type" class="msg_container__item icon_ok">
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4999 0.0839844C4.77075 0.0839844 0.083252 4.77148 0.083252 10.5007C0.083252 16.2298 4.77075 20.9173 10.4999 20.9173C16.2291 20.9173 20.9166 16.2298 20.9166 10.5007C20.9166 4.77148 16.2291 0.0839844 10.4999 0.0839844ZM10.4999 18.834C5.90617 18.834 2.16659 15.0944 2.16659 10.5007C2.16659 5.9069 5.90617 2.16732 10.4999 2.16732C15.0937 2.16732 18.8333 5.9069 18.8333 10.5007C18.8333 15.0944 15.0937 18.834 10.4999 18.834ZM15.2812 5.89648L8.41659 12.7611L5.71867 10.0736L4.24992 11.5423L8.41659 15.709L16.7499 7.37565L15.2812 5.89648Z"
                fill="white"
              />
            </svg>
          </div>
          <div v-if="'ERROR' === msg.type" class="msg_container__item icon_err">
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4999 15.709C10.7951 15.709 11.0426 15.609 11.2426 15.409C11.4419 15.2097 11.5416 14.9625 11.5416 14.6673C11.5416 14.3722 11.4419 14.1246 11.2426 13.9246C11.0426 13.7253 10.7951 13.6257 10.4999 13.6257C10.2048 13.6257 9.95756 13.7253 9.75825 13.9246C9.55825 14.1246 9.45825 14.3722 9.45825 14.6673C9.45825 14.9625 9.55825 15.2097 9.75825 15.409C9.95756 15.609 10.2048 15.709 10.4999 15.709ZM9.45825 11.5423H11.5416V5.29232H9.45825V11.5423ZM10.4999 20.9173C9.05895 20.9173 7.70478 20.6437 6.43742 20.0965C5.17006 19.55 4.06763 18.8079 3.13013 17.8704C2.19263 16.9329 1.45061 15.8305 0.904085 14.5632C0.356863 13.2958 0.083252 11.9416 0.083252 10.5007C0.083252 9.05968 0.356863 7.70551 0.904085 6.43815C1.45061 5.17079 2.19263 4.06836 3.13013 3.13086C4.06763 2.19336 5.17006 1.451 6.43742 0.903776C7.70478 0.357248 9.05895 0.0839844 10.4999 0.0839844C11.9409 0.0839844 13.2951 0.357248 14.5624 0.903776C15.8298 1.451 16.9322 2.19336 17.8697 3.13086C18.8072 4.06836 19.5492 5.17079 20.0958 6.43815C20.643 7.70551 20.9166 9.05968 20.9166 10.5007C20.9166 11.9416 20.643 13.2958 20.0958 14.5632C19.5492 15.8305 18.8072 16.9329 17.8697 17.8704C16.9322 18.8079 15.8298 19.55 14.5624 20.0965C13.2951 20.6437 11.9409 20.9173 10.4999 20.9173ZM10.4999 18.834C12.8263 18.834 14.7968 18.0267 16.4114 16.4121C18.026 14.7975 18.8333 12.827 18.8333 10.5007C18.8333 8.17426 18.026 6.20378 16.4114 4.58919C14.7968 2.97461 12.8263 2.16732 10.4999 2.16732C8.17353 2.16732 6.20304 2.97461 4.58846 4.58919C2.97388 6.20378 2.16659 8.17426 2.16659 10.5007C2.16659 12.827 2.97388 14.7975 4.58846 16.4121C6.20304 18.0267 8.17353 18.834 10.4999 18.834Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div v-if="!msg.overWriteMsg" class="msg_container__item title">
          Задача {{ msg.title }}: {{ msg.msg }}
        </div>
        <div v-else class="msg_container__item title">
          {{ msg.overWriteMsg }}
        </div>
        <div
          v-if="msg.newTabLink && msg.type === 'OK'"
          class="msg_container__item open_in_new_button"
        >
          <button
            title="Открыть в новой вкладке"
            @click="openNewTab(msg.newTabLink)"
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.20833 18.875C1.63542 18.875 1.14479 18.6712 0.736458 18.2635C0.328819 17.8552 0.125 17.3646 0.125 16.7917V2.20833C0.125 1.63542 0.328819 1.14479 0.736458 0.736458C1.14479 0.328819 1.63542 0.125 2.20833 0.125H9.5V2.20833H2.20833V16.7917H16.7917V9.5H18.875V16.7917C18.875 17.3646 18.6712 17.8552 18.2635 18.2635C17.8552 18.6712 17.3646 18.875 16.7917 18.875H2.20833ZM7.10417 13.3542L5.64583 11.8958L15.3333 2.20833H11.5833V0.125H18.875V7.41667H16.7917V3.66667L7.10417 13.3542Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
        <div
          v-if="msg.copyText && msg.type === 'OK'"
          class="msg_container__item copy_button"
        >
          <button title="Копировать" @click="copyText($event, msg.copyText)">
            <svg
              width="23"
              height="23"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.16667 17.3327C1.70833 17.3327 1.31583 17.1696 0.989167 16.8435C0.663055 16.5168 0.5 16.1243 0.5 15.666V3.99935H2.16667V15.666H11.3333V17.3327H2.16667ZM5.5 13.9993C5.04167 13.9993 4.64944 13.8363 4.32333 13.5102C3.99667 13.1835 3.83333 12.791 3.83333 12.3327V2.33268C3.83333 1.87435 3.99667 1.48185 4.32333 1.15518C4.64944 0.829071 5.04167 0.666016 5.5 0.666016H13C13.4583 0.666016 13.8508 0.829071 14.1775 1.15518C14.5036 1.48185 14.6667 1.87435 14.6667 2.33268V12.3327C14.6667 12.791 14.5036 13.1835 14.1775 13.5102C13.8508 13.8363 13.4583 13.9993 13 13.9993H5.5ZM5.5 12.3327H13V2.33268H5.5V12.3327Z"
                fill="#FFFFFF"
              ></path>
            </svg>
          </button>
        </div>
        <div class="msg_container__item close_button">
          <button @click="deleteMsgById(msg.id)" title="Закрыть">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.66659 14.7923L0.208252 13.334L6.04159 7.50065L0.208252 1.66732L1.66659 0.208984L7.49992 6.04232L13.3333 0.208984L14.7916 1.66732L8.95825 7.50065L14.7916 13.334L13.3333 14.7923L7.49992 8.95898L1.66659 14.7923Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div></TransitionGroup>
      
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msgs: [],
      deleteDelay: 10 * 1000,
    };
  },

  methods: {
    copyText(event, value) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(value);
    },
    openNewTab(url) {
      window.open(url, "_blank");
    },
    deleteMsgById(id) {
      this.msgs = this.msgs.filter((el) => el.id !== id);
    },
    deleteMsgByTag(tag) {
      this.msgs = this.msgs.filter((el) => el.tag !== tag);
    },
    addOKMsg(params) {
      this.deleteMsgByTag(params.taskId);
      const msgId = (Math.random() + 1).toString(36).substring(7);
      this.msgs.push({
        id: msgId,
        tag: params.taskId,
        msg: `ID ${this.convertToShortStr(params.taskId)} Выполнена`,
        type: "OK",
        title: params.title,
        newTabLink: params.newTabLink,
        copyText: params.copyText,
        overWriteMsg: params.overWriteMsg,
      });
      setTimeout(
        function (scope) {
          scope.deleteMsgById(msgId);
        },
        this.deleteDelay,
        this
      );
    },
    addErrorMsg(params) {
      this.deleteMsgByTag(params.taskId);
      const msgId = (Math.random() + 1).toString(36).substring(7);
      this.msgs.push({
        id: msgId,
        taskId: params.taskId,
        title: params.title,
        tag: params.taskId,
        msg: `ID ${this.convertToShortStr(params.taskId)} Ошибка`,
        type: "ERROR",
        overWriteMsg: params.overWriteMsg,
      });
      setTimeout(
        function (scope) {
          scope.deleteMsgById(msgId);
        },
        this.deleteDelay,
        this
      );
    },
    convertToShortStr(inputStr) {
      return String(inputStr).length < 8 ? inputStr : (String(inputStr).slice(0,5) + "...")
    },
    addWaitMsg(params) {
      const msgId = (Math.random() + 1).toString(36).substring(7);
      this.msgs.push({
        id: msgId,
        tag: params.taskId,
        title: params.title,
        msg: `ID ${this.convertToShortStr(params.taskId)} поставлена в очередь`,
        type: "WAITING",
        overWriteMsg: params.overWriteMsg,
      });
      setTimeout(
        function (scope) {
          scope.deleteMsgById(msgId);
        },
        this.deleteDelay,
        this
      );
    },
  },
};
</script>

<style lang="less">
@import "../assets/styles/animations.less";
#notify_bar {
  position: absolute;
  right: 0;
  

  @media (max-width: 460px) {
    width: 420px;
  }
  @media (min-width: 461px) {
    width: 445px;
    
  }

  margin-top: -19px;
  z-index: 5;
}

.notify_bar_container {
  display: flex;
  flex-direction: column;
  margin-right: 10px;

  .notify_bar_container__item {
    margin-bottom: 5px;
  }
}

.msg_container {
  display: flex;
  height: 45px;
  padding: 1px 15px;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  transition: 0.3s;
  animation: fadeIn 1s;

  button {
    all: unset;
  }

  .open_in_new_button {
    button {
      display: flex;
      justify-content: center;
      padding: 7px 7px 6px 7px;
      &:hover {
        background-color: #4151b746;
        border-radius: 50%;
        transition: 0.7s;
      }
    }
  }

  .copy_button {
    button {
      display: flex;
      justify-content: center;
      padding: 4px 2px 4px 7px;
      &:hover {
        background-color: #4151b746;
        border-radius: 50%;
        transition: 0.7s;
      }
    }
  }

  .icon {
    margin-right: 17px;
  }

  .title {
    flex-grow: 2;
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .close_button {
    margin-left: 5px;
    button {
      display: flex;
      justify-content: center;
      padding: 7px;
      &:hover {
        background-color: #4151b746;
        border-radius: 50%;
        transition: 0.7s;
      }
    }
  }

  &.msg_type_ok {
    background-color: #5bc367;
    transition: 0.3s;
  }

  &.msg_type_error {
    background-color: #f44c4b;
    transition: 0.3s;
  }

  &.msg_type_waiting {
    background-color: #1f97f4;
    transition: 0.3s;
  }
}
</style>
