<template>
  <div id="distributive_panel">
    <div class="distributive_info_container">
      <div class="distributive_info_container__item header_container">
        <div class="header_container__item title">
          <label v-if="panelMode === 'EDIT'">{{ getDistributive.type }}</label>
          <label v-else>Новый дистрибутив</label>
        </div>
        <div 
        class="header_container__item right_buttons_container"
        :style="panelMode === 'NEW' ? 'justify-content: flex-end;': ''"
        >
          <div v-if="panelMode === 'EDIT'" class="right_buttons_container__item">
            <button title="Экспорт" @click="downloadDistributive">
              <svg
                width="14"
                height="18"
                viewBox="0 0 14 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.00016 0.833984L3.66683 4.16732H6.16683V11.6673H7.8335V4.16732H10.3335M12.0002 19.1673H2.00016C1.55814 19.1673 1.13421 18.9917 0.821651 18.6792C0.509091 18.3666 0.333496 17.9427 0.333496 17.5006V7.50065C0.333496 7.05862 0.509091 6.6347 0.821651 6.32214C1.13421 6.00958 1.55814 5.83398 2.00016 5.83398H4.50016V7.50065H2.00016V17.5006H12.0002V7.50065H9.50016V5.83398H12.0002C12.4422 5.83398 12.8661 6.00958 13.1787 6.32214C13.4912 6.6347 13.6668 7.05862 13.6668 7.50065V17.5006C13.6668 17.9427 13.4912 18.3666 13.1787 18.6792C12.8661 18.9917 12.4422 19.1673 12.0002 19.1673Z"
                  fill="#010101"
                />
              </svg>
            </button>
          </div>
          <div class="right_buttons_container__item">
            <button @click="$emit('closeDistributivePanel')">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.33317 11.8346L0.166504 10.668L4.83317 6.0013L0.166504 1.33464L1.33317 0.167969L5.99984 4.83464L10.6665 0.167969L11.8332 1.33464L7.1665 6.0013L11.8332 10.668L10.6665 11.8346L5.99984 7.16797L1.33317 11.8346Z"
                  fill="#010101"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="distributive_info_container__item action_status_container">
        <action-bar
          :show="actionBarData.show"
          @close-action-bar="actionBarData.show = false"
          :status="actionBarData.status"
          :action="actionBarData.action"
          :broadDescription="actionBarData.broadDescription"
        />
      </div>

      <div
        class="distributive_info_container__item distributive_content_container"
      >
        <div
          v-if="panelMode === 'NEW'"
          class="distributive_content_container__item description"
        >
          <div class="input_with_title">
            <div class="input_with_title__item input_title">Название</div>
            <div class="input_with_title__item input_select">
              <input
                :value="getDistributive.type"
                type="text"
                @input="(event) => (settings.type = event.target.value)"
                v-on:keypress="isLetter($event)"
              />
            </div>
          </div>
        </div>
        <div class="distributive_content_container__item require_container">
          <div class="require_container__item"><label>Обязательный</label></div>
          <div class="require_container__item">
            <div class="online_container__item online_switcher">
              <button @click="onClickRequired" style="all: unset">
                <div
                  v-if="!getDistributive.required"
                  class="online_switcher_offline"
                >
                  <svg
                    width="40"
                    height="30"
                    viewBox="0 0 40 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.3276 4.05053C16.3512 3.9957 17.3735 4.00008 18.3971 4.00008C18.4041 4.00008 23.5835 4.00008 23.5835 4.00008C24.6271 4.00008 25.6494 3.9957 26.6724 4.05053C27.6024 4.09989 28.5082 4.20518 29.41 4.44044C31.3082 4.93508 32.9659 5.96824 34.1641 7.43297C35.3553 8.88838 36 10.6701 36 12.4995C36 14.3311 35.3553 16.1117 34.1641 17.5671C32.9659 19.0313 31.3082 20.065 29.41 20.5596C28.5082 20.7949 27.6024 20.8996 26.6724 20.9495C25.6494 21.0044 24.6271 20.9995 23.6035 20.9995C23.5965 20.9995 18.4159 21 18.4159 21C17.3735 20.9995 16.3512 21.0044 15.3276 20.9495C14.3982 20.8996 13.4924 20.7949 12.5906 20.5596C10.6924 20.065 9.03471 19.0313 7.83647 17.5671C6.64529 16.1117 6 14.3311 6 12.5C6 10.6701 6.64529 8.88838 7.83647 7.43297C9.03471 5.96824 10.6924 4.93508 12.5906 4.44044C13.4924 4.20518 14.3982 4.09989 15.3276 4.05053Z"
                      fill="#C9C9C9"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.5 19C18.0899 19 21 16.0899 21 12.5C21 8.91015 18.0899 6 14.5 6C10.9101 6 8 8.91015 8 12.5C8 16.0899 10.9101 19 14.5 19Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div
                  v-if="getDistributive.required"
                  class="online_switcher_online"
                >
                  <svg
                    width="40"
                    height="30"
                    viewBox="0 0 40 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.3276 4.05053C16.3512 3.9957 17.3735 4.00008 18.3971 4.00008C18.4041 4.00008 23.5835 4.00008 23.5835 4.00008C24.6271 4.00008 25.6494 3.9957 26.6724 4.05053C27.6024 4.09989 28.5082 4.20518 29.41 4.44044C31.3082 4.93508 32.9659 5.96824 34.1641 7.43297C35.3553 8.88838 36 10.6701 36 12.4995C36 14.3311 35.3553 16.1117 34.1641 17.5671C32.9659 19.0313 31.3082 20.065 29.41 20.5596C28.5082 20.7949 27.6024 20.8996 26.6724 20.9495C25.6494 21.0044 24.6271 20.9995 23.6035 20.9995C23.5965 20.9995 18.4159 21 18.4159 21C17.3735 20.9995 16.3512 21.0044 15.3276 20.9495C14.3982 20.8996 13.4924 20.7949 12.5906 20.5596C10.6924 20.065 9.03471 19.0313 7.83647 17.5671C6.64529 16.1117 6 14.3311 6 12.5C6 10.6701 6.64529 8.88838 7.83647 7.43297C9.03471 5.96824 10.6924 4.93508 12.5906 4.44044C13.4924 4.20518 14.3982 4.09989 15.3276 4.05053Z"
                      fill="#5BC367"
                    />
                    <path
                      fill-rule="evenodd"
                      transform="scale (-1, 1) translate(-1, 0)"
                      transform-origin="center"
                      clip-rule="evenodd"
                      d="M14.5 19C18.0899 19 21 16.0899 21 12.5C21 8.91015 18.0899 6 14.5 6C10.9101 6 8 8.91015 8 12.5C8 16.0899 10.9101 19 14.5 19Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="distributive_content_container__item limit">
          <div class="input_with_title">
            <div class="input_with_title__item input_title">Лимит</div>
            <div class="input_with_title__item input_select">
              <input
                type="number"
                min="0"
                :value="getDistributive.limit"
                @input="(event) => (settings.limit = event.target.value)"
              />
            </div>
          </div>
        </div>
        <div
          v-if="panelMode === 'EDIT'"
          class="distributive_content_container__item default_dist"
        >
          <div class="select_input_with_title">
            <div class="select_input_title">Дистрибутив</div>
            <div class="input_select">
              <search-select
                  :options="getDistributive.distributions"
                  v-model:selected="settings.defaultDistr"
                  style="width: 400px"
                />
            </div>
          </div>
        </div>
        <div class="distributive_content_container__item description">
          <div class="input_with_title">
            <div class="input_with_title__item input_title">Описание</div>
            <div class="input_with_title__item input_select">
              <input
                :value="getDistributive.description"
                @input="(event) => (settings.description = event.target.value)"
              />
            </div>
          </div>
        </div>
        <div class="distributive_content_container__item save">
          <button
            @click="getDistributive.type && settings.defaultDistr !== null ? onSaveDistribution() : null"
            :class="getDistributive.type && settings.defaultDistr !== null ? 'blue_button' : 'gray_button'"
          >
            {{panelMode === 'EDIT' ? 'Сохранить' : 'Создать'}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  components: {
    "action-bar": defineAsyncComponent(() =>
      import("@/components/ActionBar.vue")
    ),
    "search-select": defineAsyncComponent(() =>
      import("@/components/SearchSelect.vue")
    ),
  },
  props: {
    panelMode: {
      type: String,
      default() {
        return "EDIT";
      },
    },
    distributive: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      settings: {
        type: null,
        required: null,
        limit: null,
        defaultDistr: null,
        description: null,
      },
      actionBarData: {
        show: false,
        status: null,
        action: null,
        broadDescription: null,
      },
    };
  },
  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9]+|[.,-_]+/g.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    onClickRequired() {
      if (this.settings.required === null) {
        this.settings.required = !this.distributive.required;
        return;
      }
      this.settings.required = !this.settings.required;
    },

    downloadDistributive() {
      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.distributive, null, 2));
      var downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute(
        "download",
        `terminal_${this.distributive.type}_export` + ".json"
      );
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },

    setDefaultDistr(distr) {
      this.actionBarData = {
        status: "WAITING",
        action: "Установка дефолтного дистрибутива",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/distributions/default?distribution=${distr}`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (set defautl dist)",
            res
          );

          if ([200, 201].includes(res.data.statusCode )) {
            this.actionBarData.status = "OK";
            setTimeout(() => {
              this.ActionBar.show = false;
            }, 4000);
            return;
          }

          this.actionBarData.status = "ERROR";
          this.actionBarData.broadDescription = res.data.payload;
        })
        .catch((error) => {
          console.log("Error to set dist options", error);
          if (error.request.status === 403) {
            this.actionBarData.status = "ERROR";
            (this.actionBarData.action = "Установка дефолтного дистрибутива"),
              (this.actionBarData.broadDescription = "Недостаточно прав");
            return;
          }
          this.actionBarData.status = "ERROR";
          this.actionBarData.broadDescription = error.data;
        });
    },

    onSaveDistribution() {
      this.actionBarData = {
        status: "WAITING",
        action: "Сохранение",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/distributions/types`,
          method: "POST",
          payload: {
            distributionsType: this.getDistributive.type,
            description: this.getDistributive.description,
            distributionsLimit: this.getDistributive.limit,
            isRequired: this.getDistributive.required,
          },
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (set dist options)",
            res
          );

          if ([200, 201].includes(res.data.statusCode )) {
            this.actionBarData.status = "OK";
            if (this.settings.defaultDistr) {
              this.setDefaultDistr(this.settings.defaultDistr)
            }
            this.$emit("saveDistributive");
            return;
          }

          this.actionBarData.status = "ERROR";
          this.actionBarData.broadDescription = res.data.payload;
        })
        .catch((error) => {
          console.log("Error to set dist options", error);
          if (error.request.status === 403) {
            this.actionBarData.status = "ERROR";
            (this.actionBarData.action = "Сохранение"),
              (this.actionBarData.broadDescription = "Недостаточно прав");
            return;
          }
          this.actionBarData.status = "ERROR";
          this.actionBarData.broadDescription = error.data;
        });

      

        
      // .finally(() => {
      //   this.showLoad = false;
      // });
    },
  },
  computed: {
    getDistributive() {
      return {
        type: this.settings.type ? this.settings.type : this.distributive.type,
        defaultDistr: this.settings.defaultDistr
          ? this.settings.defaultDistr
          : this.distributive.defaultDistr,
        count: 15,
        limit:
          this.settings.limit !== null
            ? this.settings.limit
            : this.distributive.limit,
        required:
          this.settings.required !== null
            ? this.settings.required
            : this.distributive.required,
        description:
          this.settings.description !== null
            ? this.settings.description
            : this.distributive.description,
        distributions: this.distributive.distributions,
      };
    },
  },
  created() {
    this.settings.defaultDistr = this.getDistributive.defaultDistr;
  },
};
</script>

<style lang="less">
@import "../assets/styles/inputs.less";
@import "../assets/styles/buttons.less";

#distributive_panel {
  .distributive_info_container {
  position: absolute;
  width: 801px;
  height: 100%;
  right: 0;
  top: 72px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .action_status_container {
    min-height: 30px;
    max-height: 100px;
    display: flex;
    align-items: center;
    padding: 15px 0px 0px 25px;
  }

  .limit {
    input {
      width: 121px;
    }
  }
  .description {
    input {
      width: 400px;
    }
  }
  .default_dist {
    select {
      width: 315px;
    }
  }
}

.header_container {
  display: flex;
  height: 49px;
  align-items: center;
  justify-content: space-between;
  background: #d6e6ff;
  padding: 0px 25px;

  button {
    all: unset;
  }
}

.distributive_content_container {
  padding: 2px 0px 0px 25px;

  .distributive_content_container__item {
    margin-bottom: 25px;
  }
}

.right_buttons_container {
  width: 60px;
  display: flex;
  justify-content: space-between;

  button {
    width: 27px;
    height: 27px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
  }

  button:hover {
    background-color: #4151b761;
    border-radius: 50px;
    transition: 0.3s;
  }

  button:active {
    outline-color: transparent;
    outline-style: solid;
    box-shadow: 0 0 0 6px #4150b7;
    background-color: none;
  }
}

.require_container {
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
}

}


</style>
