<template>
  <div v-if="show" id="dialog_window">
    <div  class="submit_container">
      <div class="submit_container__item title">
        <label>{{ title }}</label>
      </div>
      <div class="submit_container__item msg">
        <label v-html="msg"></label>
      </div>
      <div class="submit_container__item buttons_container">
        <div class="buttons_container__item">
          <button
            @click="$emit(submitEvent)"
            class="blue_button"
            title="Подтвердить"
          >
            Подтвердить
          </button>
        </div>
        <div class="buttons_container__item">
            <button @click="$emit('cancel')" class="gray_button">Отмена</button>
            </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default() {
        return "Подтвердите действие";
      },
    },
    msg: {
      type: String,
    },
    show: {
      type: Boolean,
    },
    submitEvent: {
      type: String,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less">
@import "../assets/styles/buttons.less";
#dialog_window {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .submit_container {
    margin-top: -20%;
    background-color: #ffffff;
    border-radius: 15px;
    min-width: 350px;
    min-height: 150px;
    flex-direction: column;
    display: flex;
    align-items: center;
    padding: 15px 15px;
    justify-content: space-between;

    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);

    .title {
        font-size: 1.25rem;

    }
    .msg {
        color: rgba(0, 0, 0, 0.54);
        justify-self: normal;
    }
  }

  .buttons_container {
    display: flex;
    width: 50%;
    justify-content: center;
    .buttons_container__item {
        margin: 5px;
    }
    button {
        height: 20px;
    }
  }


}
</style>
