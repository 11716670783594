<template>
  <div id="distibution_content">
    <notify-bar ref="notifyBar" />
    <dialog-window
      @submit-terminal-delete="onDeleteDistrDialogWindow"
      @cancel="onCancelDeleteDistrDialogWindow"
      :show="dialogWindow.show"
      :msg="dialogWindow.msg"
      :submitEvent="dialogWindow.submitEvent"
    />
    <div class="distibution_content_container">
      <div class="distibution_content_container__item title_container">
        <div class="title_container__item title">
          <label>Дистрибутивы</label>
        </div>
        <div class="title_container__item">
          <button @click="onCreateDistributive" class="blue_button">
            Создать
          </button>
        </div>
      </div>
      <div class="distibution_content_container__item">
        <distributive-table
          @select-distributive="onSelectDistributive"
          @delete-distributive="onDeleteDistrTable"
          :distributives="distributives"
        />
        <distributive-panel
          v-if="selectedDistr !== null"
          @close-distributive-panel="selectedDistr = null"
          @save-distributive="onSaveDistributive"
          :distributive="getSelectedDistr"
          :panelMode="this.panelMode"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DistributiveTable from "../components/DistributiveTable.vue";
import DistributivePanel from "../components/DistrubitivePanel.vue";
import DialogWindow from "../components/DialogWindow.vue";
import NotifyBar from "../components/NotifyBar.vue";

export default {
  components: {
    "distributive-table": DistributiveTable,
    "distributive-panel": DistributivePanel,
    "dialog-window": DialogWindow,
    "notify-bar": NotifyBar,
  },
  data() {
    return {
      selectedDistr: null,
      distributives: [],
      toDelDistr: null,
      dialogWindow: {
        show: false,
        msg: "",
        submitEvent: "submitTerminalDelete",
      },
      panelMode: "EDIT" // EDIT/NEW
    };
  },
  methods: {
    getDistributives() {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/distributions/types`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log("Response from proxy obtained (get dist_types)");
          if (res.data.statusCode === 200) {
            this.distributives = [];
            res.data.payload.loadingDistrTypes.forEach((el) => {
              this.distributives.push({
                type: el.distributionsType,
                defaultDistr: el.defaultDistribution,
                count: el.distributionsCount,
                limit: el.distributionsLimit,
                required: el.isRequired,
                description: el.description,
                distributions: el.distributions,
              });
            });
            return;
          } else {
            console.log("Response proxy error (get dist_types)", res.data);
          }
        })
        .catch((error) => {
          console.log("Error to get distr", error);
        });
    },
    onSaveDistributive () {
        if (this.panelMode === 'NEW') {
            this.selectedDistr = null
        }
        this.getDistributives()

    },
    onCreateDistributive() {
      this.selectedDistr = -1;
      this.panelMode = "NEW"
    },
    onSelectDistributive(type) {
      this.selectedDistr = type;
      this.panelMode = "EDIT"
    },
    onDeleteDistrTable(type) {
      this.toDelDistr = type;
      this.dialogWindow = {
        show: true,
        msg: `Удалить дистрибутив: ${this.getDeleteDistr.type}`,
        submitEvent: "submitTerminalDelete",
      };
    },
    onDeleteDistrDialogWindow() {
      this.deleteDistribution(this.getDeleteDistr.type);
      this.dialogWindow.show = false;
    },
    onCancelDeleteDistrDialogWindow() {
      this.dialogWindow.show = false;
    },
    deleteDistribution(distr) {
      this.$refs.notifyBar.addWaitMsg({
        overWriteMsg: "Удаление дистрибутива",
      });
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/distributions/types?distributionsType=${distr}`,
          method: "DELETE",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (del dist options)",
            res
          );

          if (res.data.statusCode === 200) {
            this.getDistributives();
            this.$refs.notifyBar.addOKMsg({
              overWriteMsg: "Дистрибутив удален",
            });
            return;
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Ошибка удаления дистрибутива",
          });
        })
        .catch((error) => {
          console.log("Error to del dist options", error);
          if (error.request.status === 403) {
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: "Недостаточно прав",
            });
            return
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Ошибка удаления дистрибутива",
          });
        });
    },
  },
  created() {
    this.getDistributives();
  },
  computed: {
    getSelectedDistr() {
      if (this.selectedDistr == -1) {
        return {
          type: "",
          limit: 10,
          required: false,
          description: "",
          tag: "NEW"
        };
      }
      return this.distributives.find(el => {return el.type === this.selectedDistr});
    },
    getDeleteDistr() {
      return this.distributives.find(el => {return el.type === this.toDelDistr});
    },
  },
};
</script>

<style lang="less">
@import "../assets/styles/buttons.less";

.distibution_content_container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  
}

.title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }
  .blue_button {
    height: 20px;
  }
}
</style>
